<template>
    <div class="listing-comparables-list">
        <div class="columns mb-4">
            <div class="column col-md-12">
                <div class="columns">
                    <div class="column col-auto">
                        <label class="form-label label-sm text-bold">Filter</label>
                    </div>
                    <div class="column col-auto">
                        <div class="btn-group">
                            <default-button
                                v-for="filter in show_filters"
                                :key="filter.id"
                                size="sm"
                                :active="show_filter === filter.id"
                                @click.prevent="show_filter = filter.id"
                            >
                                {{ filter.label }}
                            </default-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-auto">
                <div class="columns">
                    <div
                        v-if="list_mode !== DISPLAY_MODE_LIST"
                        class="column col-auto"
                    >
                        <label class="form-label label-sm text-bold">Select</label>
                    </div>
                    <div
                        v-if="list_mode !== DISPLAY_MODE_LIST"
                        class="column col-auto"
                    >
                        <div class="btn-group">
                            <default-button
                                v-for="select_preset in select_presets"
                                :key="select_preset.id"
                                size="sm"
                                @click.prevent="apply_preset(select_preset)"
                            >
                                {{ select_preset.label }}
                            </default-button>
                        </div>
                    </div>
                    <div class="column col-auto">
                        <list-mode-picker
                            :list-mode="list_mode"
                            size="sm"
                            @input="set_list_mode"
                        />
                    </div>
                </div>
            </div>
        </div>
        <listings-list
            :list-mode="list_mode"
            :listings="comparables"
            :anchored-to="{ id: listing.id, latitude: listing.latitude, longitude: listing.longitude }"
            :state="state"
            :data-version="data_version"
            :loading="loading"
            :selection="selection"
            @sort="sort_by"
            @page="goto_page"
            @select="adjust_selection"
            @select-all-none="set_selection"
        >
            <template v-slot:totals>
                {{ translate("1 comparable listing:::{n} comparable listings", { n: state.found }, state.found) }}
            </template>
        </listings-list>
    </div>
</template>

<script>
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import can_select_listings from "@/custom/mixins/CanSelectListings"
import { lists_listings } from "@/custom/mixins/ListsListings"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import ListingsList from "@/custom/components/ListingsList"

export default {
    name: "ComparablesList",
    mixins: [is_remote_data_table_source, can_select_listings, lists_listings],
    components: {
        DefaultButton,
        ListingsList
    },
    mounted () {
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"))
        }, 1000)
    },
    watch: {
        comparablesQuery: "reset",
        comparableIds: "reset_selection"
    },
    methods: {
        reset_selection () {
            this.selection = this.comparableIds ? this.comparables.filter(comparable => this.comparableIds.indexOf(comparable.id) >= 0).map(comparable => comparable.id) : []
        },
        pre_load () {
            this.set_state_values({
                filter: this.listing.id,
                advanced_query: {
                    ...this.comparablesQuery
                }
            })
        },
        post_load () {
            this.reset_selection()
        },
        filter_rows (rows) {
            if(this.state.filter_on && !this.state.filter_on.match(/^_/) && this.show_filter) {
                return rows.filter((row) => {
                    if((this.state.filter_on === "comparables") && !!this.show_filter) {
                        if(this.show_filter === "active") return !!row.is_available
                        if(this.show_filter === "sold") return !row.is_available
                        if(this.show_filter === "selection") {
                            return this.selection.indexOf(row.id) >= 0
                        }
                    }
                    return true
                })
            }
            return rows
        },
        start_selecting (mode = "show") {
            this.selection_mode = mode
        },
        adjust_selection (listing_id) {
            let selection = [...this.selection]
            this.toggle_selection(listing_id, selection)
            selection = this.comparables.filter(comparable => selection.indexOf(comparable.id) >= 0).map(comparable => comparable.id) || []
            this.selection = selection
            this.$emit("input", this.selection, "comparable_ids")
        },
        set_selection (listing_ids, select) {
            if(select) this.selection = this.comparables.filter(comparable => listing_ids.indexOf(comparable.id) >= 0).map(comparable => comparable.id) || []
            else this.selection = []
            this.$emit("input", this.selection, "comparable_ids")
        },
        apply_preset (select_preset) {
            if(select_preset.id === true) this.$emit("input", this.comparables.map(comparable => comparable.id), "comparable_ids")
            else this.$emit("input", [], "comparable_ids")
        },
        apply_sort_preset (sort_preset) {
            const sort_dir = ((sort_preset.id !== this.state.sort_by) || (this.state.sort_dir === "desc")) ? "asc" : "desc"
            this.set_state_values({
                sort_by: sort_preset.id,
                sort_dir
            })
            return this.refresh()
        }
    },
    computed: {
        list_mode_cookie_name () {
            return "derekgillette-comparables-editor"
        },
        state_identifier () {
            return "comparables"
        },
        can_refresh () {
            if(this.profile_id && this.listing.id) {
                if(this.is_at_least_administrator) return this.is_searchable
                return true
            }
            return false
        },
        sale_ranges () {
            const ranges = []
            for(let loop = 1; loop <= 6; loop++) {
                ranges.push({ id: loop, name: this.translate("1 month:::{n} months", { n: loop }, loop) })
            }
            return ranges
        },
        fields () {
            return ["fieldset::list"]
        },
        is_searchable () {
            return (
                !!this.comparablesQuery &&
                (
                    (this.comparablesQuery.min_price > 0) ||
                    (this.comparablesQuery.max_price > 0) ||
                    (this.comparablesQuery.min_sale_price > 0) ||
                    (this.comparablesQuery.max_sale_price > 0) ||
                    this.comparablesQuery.types.length ||
                    this.comparablesQuery.neighborhoods.length ||
                    (this.comparablesQuery.min_size > 0) ||
                    (this.comparablesQuery.max_size > 0) ||
                    this.comparablesQuery.age ||
                    (this.comparablesQuery.min_land_size > 0) ||
                    (this.comparablesQuery.max_land_size > 0) ||
                    this.comparablesQuery.amenities.length ||
                    // this.comparablesQuery.on_market_on ||
                    // this.comparablesQuery.accepted_on ||
                    // this.comparablesQuery.sold_on ||
                    this.comparablesQuery.availability_changed_on ||
                    !!this.comparablesQuery.or_sold_within
                )
            )
        },
        comparables () {
            return this.filtered_rows
        },
        active_comparables () {
            return this.rows.filter(listing => listing.is_available)
        },
        sold_comparables () {
            return this.rows.filter(listing => !listing.is_available)
        },
        select_presets () {
            const items = [
                { id: true, label: this.translate("All") },
                { id: false, label: this.translate("None") }
            ]
            return items
        },
        show_filters () {
            const items = [
                { id: null, label: this.translate("All") }
            ]
            let count = this.active_comparables.length
            if(count) items.push({ id: "active", label: this.translate("{n} Active", { n: count }) })
            count = this.sold_comparables.length
            if(count) items.push({ id: "sold", label: this.translate("{n} Sold", { n: count }) })
            if(!!this.comparableIds && !!this.comparableIds.length) items.push({ id: "selection", label: this.translate("All Selected") })
            return items
        }
    },
    props: {
        listing: {
            type: Object,
            required: true
        },
        comparablesQuery: {
            type: Object,
            default () {
                return {}
            }
        },
        comparableIds: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            entity: "listing",
            default_state: {
                per_page: 150,
                page: 1,
                sort_by: "updated_at",
                sort_dir: "desc",
                filter_on: "comparables",
                filter: null,
                archived: false,
                search: "",
                advanced_query: {},
                total: 0,
                found: 0
            },
            show_filter: null,
            sale_range: 1
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";
</style>
