<template>
    <div v-if="!!record && record.is_available" class="chance-of-selling">
        <div class="columns">
            <form-input
                id="chance_of_selling_baseline"
                name="chance_of_selling_baseline"
                v-model="record.chance_of_selling_baseline"
                type="float"
                :min="0"
                :max="100"
                :float-step="5"
                :required="true"
                :editable="true"
                :error="has_error('chance_of_selling_baseline')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("Initial Chance of Selling") }}</span>
                    <tooltip-widget
                        :content="translate('Based on your past experience, what is the chance of selling this property on day one?')"
                        class="ml-1"
                    />
                </template>
            </form-input>
            <form-input
                id="chance_of_selling_showings_count_trigger"
                name="chance_of_selling_showings_count_trigger"
                v-model="record.chance_of_selling_showings_count_trigger"
                type="number"
                :min="0"
                :required="true"
                :error="has_error('chance_of_selling_showings_count_trigger')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("# of Showings") }}</span>
                    <tooltip-widget
                        :content="translate('How many showings would you expect to have before a sale?')"
                        class="ml-1"
                    />
                </template>
                <template v-slot:right_addon>
                    <span class="input-group-addon">
                        {{ translate("showing(s)") }}
                    </span>
                </template>
            </form-input>
            <form-input
                id="chance_of_selling_showing_weight"
                name="chance_of_selling_showing_weight"
                v-model="record.chance_of_selling_showing_weight"
                type="float"
                :min="0"
                :max="100"
                :float-step="0.5"
                glyph="percent"
                :required="true"
                :error="has_error('chance_of_selling_showing_weight')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("Extra Showing Penalty") }}</span>
                    <tooltip-widget
                        :content="translate('Decrease the chance of selling by this value for each additional showing.')"
                        class="ml-1"
                    />
                </template>
            </form-input>
            <form-input
                id="chance_of_selling_star_weight"
                name="chance_of_selling_star_weight"
                v-model="record.chance_of_selling_star_weight"
                type="float"
                :min="0"
                :max="100"
                :float-step="0.5"
                glyph="percent"
                :required="true"
                :error="has_error('chance_of_selling_star_weight')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("Feedback Star Weight") }}</span>
                    <tooltip-widget
                        :content="star_weight_tooltip"
                        class="ml-1"
                    />
                </template>
            </form-input>

            <form-input
                id="chance_of_selling_dom_without_showings_trigger"
                name="chance_of_selling_dom_without_showings_trigger"
                v-model="record.chance_of_selling_dom_without_showings_trigger"
                type="number"
                :min="0"
                :required="true"
                :error="has_error('chance_of_selling_dom_without_showings_trigger')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("No Showing Days") }}</span>
                    <tooltip-widget
                        :content="translate('How long do you feel this listing can go without any showings?')"
                        class="ml-1"
                    />
                </template>
                <template v-slot:right_addon>
                    <span class="input-group-addon">
                        {{ translate("day(s)") }}
                    </span>
                </template>
            </form-input>
            <form-input
                id="chance_of_selling_dom_without_showings_day_weight"
                name="chance_of_selling_dom_without_showings_day_weight"
                v-model="record.chance_of_selling_dom_without_showings_day_weight"
                type="float"
                :min="0"
                :max="100"
                :float-step="0.5"
                glyph="percent"
                :required="true"
                :error="has_error('chance_of_selling_dom_without_showings_day_weight')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("No Showing Day Penalty") }}</span>
                    <tooltip-widget
                        :content="translate('Decrease the chance of selling by this much for each additional day without any showings.')"
                        class="ml-1"
                    />
                </template>
            </form-input>

            <form-input
                id="chance_of_selling_dom_trigger"
                name="chance_of_selling_dom_trigger"
                v-model="record.chance_of_selling_dom_trigger"
                type="number"
                :min="0"
                :required="true"
                :error="has_error('chance_of_selling_dom_trigger')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("Typical DOM") }}</span>
                    <tooltip-widget
                        :content="translate('How long do you think it will take to sell this listing?')"
                        class="ml-1"
                    />
                </template>
                <template v-slot:right_addon>
                    <span class="input-group-addon">
                        {{ translate("day(s)") }}
                    </span>
                </template>
            </form-input>
            <form-input
                id="chance_of_selling_dom_weight"
                name="chance_of_selling_dom_weight"
                v-model="record.chance_of_selling_dom_weight"
                type="float"
                :min="0"
                :max="100"
                :float-step="0.5"
                glyph="percent"
                :required="true"
                :error="has_error('chance_of_selling_dom_weight')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("Extra DOM Penalty") }}</span>
                    <tooltip-widget
                        :content="translate('Decrease the chance of selling by this much if the listing stays on market longer.')"
                        class="ml-1"
                    />
                </template>
            </form-input>

            <form-input
                id="chance_of_selling_months_of_inventory_baseline"
                name="chance_of_selling_months_of_inventory_baseline"
                v-model="record.chance_of_selling_months_of_inventory_baseline"
                type="number"
                :min="0"
                :required="true"
                :error="has_error('chance_of_selling_months_of_inventory_baseline')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("Baseline MOI") }}</span>
                    <tooltip-widget
                        :content="translate('How many months of inventory do we start with for this type of listing? You can use the pin button on the Months of Inventory stat above to lock this value, or estimate it yourself.')"
                        class="ml-1"
                    />
                </template>
                <template v-slot:right_addon>
                    <span class="input-group-addon">
                        {{ translate("month(s)") }}
                    </span>
                </template>
            </form-input>
            <form-input
                id="chance_of_selling_months_of_inventory_month_weight"
                name="chance_of_selling_months_of_inventory_month_weight"
                v-model="record.chance_of_selling_months_of_inventory_month_weight"
                type="float"
                :min="0"
                :max="100"
                :float-step="0.5"
                glyph="percent"
                :required="true"
                :error="has_error('chance_of_selling_months_of_inventory_month_weight')"
                class="column col-3 col-md-6 col-sm-12"
                @input="save"
            >
                <template v-slot:label>
                    <span class="text-small-xl">{{ translate("MOI Weight") }}</span>
                    <tooltip-widget
                        :content="translate('By how much should the chance of selling change when the current months of inventory changes? If the current months of inventory is higher than the baseline, the chance of selling will decrease by this value. (and vice-versa)')"
                        class="ml-1"
                    />
                </template>
            </form-input>
        </div>

        <div class="card card-overflow-visible mt-8">
            <div class="card-body">
                <chance-of-selling-graphs
                    :listing="record"
                    :editable="is_at_least_seller_for_listing(record)"
                    @input="save"
                />
            </div>
            <div v-if="record" class="card-footer text-center">
                <div class="text-tiny">
                    {{ translate("Calculations start on") }}:&nbsp;{{ (record.chance_of_selling_reset_on || record.on_market_on) | nibnut.date }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import handles_errors from "@/nibnut/mixins/HandlesErrors"
import is_editor from "./IsEditor"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import TooltipWidget from "@/custom/components/TooltipWidget"
import ChanceOfSellingGraphs from "@/custom/components/ChanceOfSellingGraphs"

export default {
    name: "ChanceOfSelling",
    mixins: [handles_errors, is_editor],
    components: {
        FormInput,
        TooltipWidget,
        ChanceOfSellingGraphs
    },
    watch: {
        allErrors: "reset_errors"
    },
    methods: {
        reset_errors () {
            this.errors = this.allErrors
        },
        save (value, field) {
            this.$emit("input", value, field)
        }
    },
    computed: {
        star_weight_tooltip () {
            return [
                this.translate("Each feedback star is worth this much."),
                this.translate("1 star reviews will decrease the chance of selling by twice this value"),
                this.translate("2 stars will decrease by this value"),
                this.translate("4 stars will increase by this value"),
                this.translate("5 stars will increase by twice this value.")
            ].join("\n")
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        allErrors: {
            type: Object,
            default () {
                return null
            }
        }
    },
    data () {
        return {
            entity: "record",
            data_version: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.chance-of-selling {
    .form-label.disabled-field {
        width: 100%;
        .btn.btn-link {
            height: auto;
            line-height: normal;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    @media (min-width: $size-md) and (max-width: $size-xl) {
        .text-small-xl {
            font-size: 0.8em;
        }
    }
}
</style>
