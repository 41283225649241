<template>
    <page
        id="listing-comparables"
        :title="title"
        :status="page_status"
        :is-root="true"
    >
        <template v-slot:page-title>
            <div>
                <router-link
                    v-if="edited_record"
                    :to="{ name: 'listing.edit', params: {id: edited_record.id } }"
                    v-slot="{ href, navigate }"
                    custom
                >
                    <base-link
                        ref="link"
                        :href="href"
                        class="btn btn-light btn-link btn-sm"
                        @click.stop="navigate"
                    >
                        <open-icon glyph="chevron-left" /> {{ translate("Back to listing") }}
                    </base-link>
                </router-link>
                <h3 v-if="edited_record">
                    {{ translate("Intelligence Settings for") }}
                    <router-link
                        :to="{ name: 'listing.edit', params: {id: edited_record.id } }"
                        v-slot="{ href, navigate }"
                        custom
                    >
                        <base-link
                            ref="link"
                            :href="href"
                            @click.stop="navigate"
                        >
                            {{ edited_record.computed_display_title }}
                        </base-link>
                    </router-link>
                </h3>
                <h3 v-else>
                    {{ translate("Intelligence Settings") }}
                </h3>
            </div>
        </template>
        <div v-if="edited_record">
            <h4>{{ translate("Comparables") }}</h4>
            <listing-search-panel
                :query="edited_record.comparables_query"
                @input="adjust_query"
            />

            <div class="columns justify-content-center my-8">
                <div v-if="!!edited_record.comparables_stats && !!edited_record.comparables_stats.avg_dom" class="column col-3 col-xl-5 col-lg-6 col-sm-12">
                    <counted-statistic
                        :title="translate('Avg DOM')"
                        :count="edited_record.comparables_stats.avg_dom"
                        glyph="calendar"
                        color="dark"
                    />
                </div>
                <div v-if="!!edited_record.comparables_stats && !!edited_record.comparables_stats.moi" class="column col-3 col-xl-5 col-lg-6 col-sm-12">
                    <counted-statistic
                        :title="translate('Months of Inventory')"
                        :count="edited_record.comparables_stats.moi"
                        glyph="home"
                        color="dark"
                    >
                        <template v-slot:controls>
                            <default-button
                                flavor="link"
                                color="light"
                                size="sm"
                                :title="translate('Set baseline')"
                                @click.prevent="save(edited_record.comparables_stats.moi, 'chance_of_selling_months_of_inventory_baseline')"
                            >
                                <open-icon glyph="thumbtack" />
                            </default-button>
                        </template>
                    </counted-statistic>
                </div>
            </div>

            <comparables-list
                :listing="edited_record"
                :comparables-query="edited_record.comparables_query"
                :comparable-ids="edited_record.comparable_ids"
                class="mb-8"
                @input="save"
            />

            <h4>
                {{ translate("Chance Of Selling") }}
                <default-button
                    size="sm"
                    color="primary"
                    :title="translate('Reset Calculations...')"
                    class="ml-4"
                    @click.prevent="reset_calculations"
                >
                    <open-icon glyph="undo" />
                </default-button>
                <default-button
                    v-if="edited_record"
                    flavor="link"
                    size="sm"
                    :title="translate('Lock/Unlock Chance of Selling Display')"
                    class="float-right"
                    @click.prevent="save(edited_record.chance_of_selling_lock ? 0 : edited_record.latest_chance_of_selling, 'chance_of_selling_lock')"
                >
                    <open-icon :glyph="edited_record.chance_of_selling_lock ? 'lock' : 'unlock'" />
                </default-button>
            </h4>
            <chance-of-selling
                :record="edited_record"
                :all-errors="errors"
                @input="save"
            />
        </div>

        <modal-dialog
            id="reset-chance-of-selling"
            :show.sync="resetting_calculations"
        >
            <template v-slot:title><span class="h5">{{ translate("Reset Chance Of Selling Calculations") }}</span></template>

            <p>{{ translate("This will reset the calculations as if a price change had occured: only showings and feedback from today onwards will be considered.") }}</p>
            <p>{{ translate("A good use case for this would be when major renovations are done, without a price change to the listing.") }}</p>

            <form-input
                id="chance_of_selling_baseline"
                name="chance_of_selling_baseline"
                v-model="chance_of_selling_baseline"
                type="float"
                :min="0"
                :max="100"
                :float-step="5"
                :required="true"
                :editable="true"
            >
                <template v-slot:label>
                    {{ translate("Restart Chance of Selling at") }}
                </template>
            </form-input>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="saving('chance_of_selling_baseline')"
                        class="mr-2"
                        @click.prevent="resetting_calculations = false"
                    >
                        {{ translate("Cancel") }}
                    </default-button>
                    <default-button
                        :waiting="saving('chance_of_selling_baseline')"
                        :disabled="saving('chance_of_selling_baseline')"
                        color="primary"
                        class="ml-2"
                        @click.prevent="reset_calculations_confirmed"
                    >
                        {{ translate("Reset") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "@/nibnut/mixins"

import { ModalDialog, FormInput, BaseLink, DefaultButton, OpenIcon } from "@/nibnut/components"
import {
    ListingSearchPanel,
    CountedStatistic
} from "@/custom/components"
import ChanceOfSelling from "./ChanceOfSelling"
import ComparablesList from "./ComparablesList"

export default {
    mixins: [is_record_editor_page],
    components: {
        ModalDialog,
        FormInput,
        BaseLink,
        DefaultButton,
        OpenIcon,
        ListingSearchPanel,
        CountedStatistic,
        ChanceOfSelling,
        ComparablesList
    },
    methods: {
        save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value
                if(this.edited_record.id) {
                    return this.save_data_for_record_id(
                        this.entity,
                        this.edited_record.id,
                        {
                            [field]: this.edited_record[field],
                            ...this.reload_query
                        }
                    )
                }
            }
            return Promise.resolve()
        },
        adjust_query (value, field) {
            const query = {
                ...this.edited_record.comparables_query,
                [field]: value
            }
            this.save(query, "comparables_query")
        },
        reset_calculations () {
            this.chance_of_selling_baseline = this.edited_record.chance_of_selling_baseline
            this.resetting_calculations = true
        },
        reset_calculations_confirmed () {
            this.save_data_for_record_id(
                this.entity,
                this.edited_record.id,
                {
                    chance_of_selling_baseline: this.chance_of_selling_baseline,
                    chance_of_selling_reset_on: this.$dayjs().format("YYYY-MM-DD")
                }
            ).then(() => {
                this.resetting_calculations = false
            })
        }
    },
    computed: {
        title () {
            if(!this.edited_record) return this.translate("Comparables")
            return this.translate("Comparables for {name}", { name: this.edited_record.computed_display_title })
        },
        reload_query () {
            return {
                relation_ids: this.relation_ids,
                fields: ["fieldset::intelligence"]
            }
        }
    },
    data () {
        return {
            entity: "listing",
            entityName: "Listing:::Listings",
            relation_ids: [],
            resetting_calculations: false,
            chance_of_selling_baseline: 100
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#listing-comparables {
}
</style>
